<template>
    <div class="full-height-layout fill px-4">
        <div class="row">
            <template v-for="(page, key) in pages">
                <div :key="key" v-if="isAllowedTo(page.actionName)" class="col-3 full-height-layout">
                    <mercur-card class="fill full-height-layout">
                        <h2 class="font-weight-normal mt-3 mb-4">{{page.title}}</h2>
                        <p>{{page.description}}</p>
                        <div class="text-right">
                            <mercur-button class="btn text-uppercase" :to="page.route">go to {{page.title}}</mercur-button>
                        </div>
                    </mercur-card>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LogisticsOverview',
    data () {
        return {
            pages: [
                {
                    title: 'Carriers',
                    description: `To manage Carriers, Carrier Service Levels and their configuration.`,
                    route: {
                        name: 'CarriersOverview',
                    },
                    actionName: 'getCarriers',
                },
                {
                    title: 'Extra days',
                    description: `Section to manage extra days for suppliers, carriers, countries and products`,
                    route: {
                        name: 'ExtraDaysOverview',
                    },
                    actionName: 'getAllExtraDaysConfiguration',
                },
                /* {
                    title: 'Hs Codes',
                    description: `Section to manage HsCodes for Product Templates`,
                    route: {
                        name: 'HsCodesOverview',
                    },
                    actionName: 'getAllHsCodesConfiguration',
                }, */
            ],
        }
    },
}
</script>
